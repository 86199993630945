import {
  MessageBox,
} from 'element-ui'
import { loadLanguageAsync } from "@/utils/i18n";
import i18n from "@/utils/i18n.js"
import Cookies from 'js-cookie'

let isHandlerReLogin = false

export default {
  getBinding() {
    let binding = window.$CCDK.CCToken.getToken()
    if (!binding || window.location.href.indexOf("binding") > -1) {
      this.reLogin();
    } else {
      // 刷新有效期，而且有的老功能，依赖这个binding了
      Cookies.set('binding', binding, { expires: 1 / 12 }, { sameSite: 'Strict' })
      Cookies.set('JSESSIONID', binding, { expires: 1 / 12 }, { sameSite: 'Strict' })
    }
    return binding
  },

  /**
   * 重新登录
   */
  async reLogin() {
    if (isHandlerReLogin) return
    isHandlerReLogin = true
    const isPrivate = window.Glod.ENV_TYPE === 'private'

    const toLoginPage = () => {
      let loginUrl = window.Glod.LOGIN_URL
      if (loginUrl.includes('https://') || loginUrl.includes('http://')) {
        loginUrl = loginUrl.replace(/(?<!http[s]{0,1}:)\/\//g, () => '/')
      }
      // window.close()
      // window.open(loginUrl, '_blank');
      window.location.href = loginUrl
      window.location.reload()
    }

    if (isPrivate) {
      return toLoginPage()
    }
    await loadLanguageAsync(navigator.language === "zh-CN" ? "zh" : "en");
    MessageBox.alert(i18n.t('message.error.object.relogin'), i18n.t('label_popup_infoz'), {
      confirmButtonText: i18n.t('vue_label_normal_reregister'),
      type: "waring",
      callback: () => {
        sessionStorage.removeItem("application");
        sessionStorage.removeItem("applicationTab");
        window.close()
        window.open(window.Glod.LOGIN_URL, '_blank');
      }
    })
    // 关闭loading
    document.getElementById("lightning-loading").style.display = "none";
  },
  /**
   * 
   * @param {number} qps 
   * @returns 延迟时间
   */
  qps() {
    let requestNum = 0
    let lastTime = 0
    return function (qps) {
      let now = Date.now();
      (now - lastTime > 1500) ? (requestNum = 0) : (requestNum++)
      lastTime = now;
      requestNum++;
      return parseInt(requestNum / qps) * 1000
    }
  },
  // 生产uuid
  getUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
}